import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, where, getDocs, addDoc, updateDoc, doc, runTransaction, getDoc } from 'firebase/firestore';
import { FaFileInvoiceDollar, FaMoneyBillWave, FaCheck, FaTimes, FaEnvelope, FaSearch, FaSortAmountDown, FaSortAmountUp } from 'react-icons/fa';
import { jsPDF } from "jspdf";
import "jspdf-autotable";

function Økonomi() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [selectedBookings, setSelectedBookings] = useState({});
  const [expandedUser, setExpandedUser] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [amountDue, setAmountDue] = useState({});
  const [bookingPrices, setBookingPrices] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('descending');
  const [defaultPrice, setDefaultPrice] = useState(null);

  useEffect(() => {
    fetchUsers();
    fetchBookings();
    fetchDefaultPrice();
  }, []);

  useEffect(() => {
    if (users.length > 0 && bookings.length > 0) {
      calculateAmountDue();
    }
  }, [users, bookings]);

  useEffect(() => {
    filterAndSortUsers();
  }, [users, searchTerm, sortOrder, amountDue]);

  const fetchUsers = async () => {
    try {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const usersList = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setUsers(usersList);
    } catch (error) {
      console.error('Fejl ved hentning af brugere:', error);
    }
  };

  const fetchBookings = async () => {
    try {
      const now = new Date();
      const bookingsCollection = collection(db, 'bookings');
      const q = query(bookingsCollection, where("date", "<", now));
      const bookingsSnapshot = await getDocs(q);
      const bookingsList = bookingsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date.toDate()
      }));
      setBookings(bookingsList);
    } catch (error) {
      console.error('Fejl ved hentning af bookinger:', error);
    }
  };

  const fetchDefaultPrice = async () => {
    try {
      const defaultPriceDoc = await getDoc(doc(db, 'settings', 'defaultPrice'));
      if (defaultPriceDoc.exists()) {
        setDefaultPrice(defaultPriceDoc.data().price);
      }
    } catch (error) {
      console.error('Fejl ved hentning af standardpris:', error);
    }
  };

  const calculateAmountDue = () => {
    const amounts = {};
    users.forEach(user => {
      const userBookings = bookings.filter(b => b.userId === user.id && !b.invoiced);
      const totalStudents = userBookings.reduce((sum, booking) => sum + booking.numberOfStudents, 0);
      const pricePerStudent = getUserPrice(user);
      amounts[user.id] = totalStudents * pricePerStudent;
    });
    setAmountDue(amounts);
  };

  const filterAndSortUsers = () => {
    let filtered = users.filter(user =>
      user.koereskole.toLowerCase().includes(searchTerm.toLowerCase())
    );

    filtered.sort((a, b) => {
      const amountA = amountDue[a.id] || 0;
      const amountB = amountDue[b.id] || 0;
      return sortOrder === 'ascending' ? amountA - amountB : amountB - amountA;
    });

    setFilteredUsers(filtered);
  };

  const toggleSortOrder = () => {
    setSortOrder(prevOrder => prevOrder === 'ascending' ? 'descending' : 'ascending');
  };

  const handleSelectBooking = (bookingId) => {
    setSelectedBookings(prev => ({
      ...prev,
      [bookingId]: !prev[bookingId]
    }));
  };

  const getUserPrice = (user) => {
    if (user.pricePerStudent === 0) {
      return 0;
    } else if (user.pricePerStudent) {
      return user.pricePerStudent;
    } else {
      return defaultPrice !== null ? defaultPrice : 520; // Fallback til 520 hvis ingen standardpris er sat
    }
  };

  const handleFakturerKunde = (user) => {
    setSelectedUser(user);
    const prices = {};
    bookings
      .filter(b => b.userId === user.id && !b.invoiced)
      .forEach(booking => {
        const price = getUserPrice(user);
        prices[booking.id] = price * booking.numberOfStudents;
      });
    setBookingPrices(prices);
    setShowPopup(true);
  };

  const handlePriceChange = (bookingId, newPrice) => {
    setBookingPrices(prev => ({
      ...prev,
      [bookingId]: newPrice
    }));
  };

  const handleSelectAllBookings = () => {
    const allBookingIds = bookings
      .filter(b => b.userId === selectedUser.id && !b.invoiced)
      .map(b => b.id);
    const newSelectedBookings = {};
    allBookingIds.forEach(id => {
      newSelectedBookings[id] = true;
    });
    setSelectedBookings(newSelectedBookings);
  };

  const calculateSummary = () => {
    const selectedBookingsList = bookings.filter(b => b.userId === selectedUser.id && !b.invoiced && selectedBookings[b.id]);
    const totalStudents = selectedBookingsList.reduce((sum, booking) => sum + booking.numberOfStudents, 0);
    const totalPrice = selectedBookingsList.reduce((sum, booking) => sum + (bookingPrices[booking.id] || getUserPrice(selectedUser) * booking.numberOfStudents), 0);
    return { totalStudents, totalPrice };
  };

  return (
    <div className="økonomi p-4 sm:p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl sm:text-4xl font-extrabold mb-6 sm:mb-8 text-center text-gray-800">Økonomi</h2>
      
      <div className="max-w-7xl mx-auto mb-6">
        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
          <div className="relative flex-grow">
            <input
              type="text"
              placeholder="Søg efter køreskole..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-3 pl-10 pr-4 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
          <button
            onClick={toggleSortOrder}
            className="p-3 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent flex items-center justify-center"
          >
            {sortOrder === 'ascending' ? <FaSortAmountUp /> : <FaSortAmountDown />}
            <span className="ml-2 hidden sm:inline">Sorter {sortOrder === 'ascending' ? 'stigende' : 'faldende'}</span>
          </button>
        </div>
      </div>

      <div className="max-w-7xl mx-auto">
        <div className="hidden sm:block"> {/* Desktop version */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <table className="w-full">
              <thead className="bg-gray-800 text-white">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Køreskole</th>
                  <th className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider">Tilgodehavende</th>
                  <th className="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider">Handling</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredUsers.map(user => (
                  <tr key={user.id} className="hover:bg-gray-50 transition-colors duration-200">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">{user.koereskole}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <div className="text-sm text-gray-900 font-semibold inline-flex items-center">
                        <FaMoneyBillWave className="mr-2 text-green-500" />
                        {amountDue[user.id] || 0} DKK
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        onClick={() => handleFakturerKunde(user)}
                        className="text-blue-600 hover:text-blue-900 px-3 py-1 bg-blue-100 rounded-full"
                      >
                        <FaFileInvoiceDollar className="inline mr-1" /> Fakturér kunde
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="sm:hidden"> {/* Mobile version */}
          <div className="space-y-4">
            {filteredUsers.map(user => (
              <div key={user.id} className="bg-white rounded-lg shadow-lg p-4">
                <div className="flex justify-between items-center mb-2">
                  <div className="flex items-center">
                    <span className="font-medium text-gray-900">{user.koereskole}</span>
                  </div>
                </div>
                <div className="mb-4">
                  <span className="text-sm text-gray-600">Tilgodehavende: </span>
                  <span className="text-sm font-semibold">
                    <FaMoneyBillWave className="inline mr-1 text-green-500" />
                    {amountDue[user.id] || 0} DKK
                  </span>
                </div>
                <div className="flex justify-center">
                  <button
                    onClick={() => handleFakturerKunde(user)}
                    className="text-blue-600 hover:text-blue-900 px-3 py-1 bg-blue-100 rounded-full"
                  >
                    <FaFileInvoiceDollar className="inline mr-1" /> Fakturér kunde
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {showPopup && selectedUser && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-full w-full sm:w-auto sm:min-w-[600px] max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <h3 className="text-2xl sm:text-4xl font-extrabold mb-4 text-center text-gray-800">{selectedUser.koereskole}</h3>
              <p className="mb-4 text-gray-600 text-center">Pris pr. elev: <span className="font-semibold">{getUserPrice(selectedUser)} DKK</span></p>
              
              <div className="mb-4">
                <h4 className="font-semibold text-lg text-gray-700">Vælg bookinger til fakturering:</h4>
              </div>

              <div className="overflow-x-auto sm:overflow-x-visible">
                <table className="w-full">
                  <thead className="bg-gray-800 text-white">
                    <tr>
                      <th className="px-2 sm:px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Vælg</th>
                      <th className="px-2 sm:px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Dato</th>
                      <th className="px-2 sm:px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Tidspunkt</th>
                      <th className="px-2 sm:px-6 py-3 text-center text-xs font-medium uppercase tracking-wider">Antal elever</th>
                      <th className="px-2 sm:px-6 py-3 text-right text-xs font-medium uppercase tracking-wider">Pris</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {bookings.filter(b => b.userId === selectedUser.id && !b.invoiced).map(booking => (
                      <tr key={booking.id} className="hover:bg-gray-50 transition-colors duration-200">
                        <td className="px-2 sm:px-6 py-4 whitespace-nowrap">
                          <input
                            type="checkbox"
                            checked={selectedBookings[booking.id] || false}
                            onChange={() => handleSelectBooking(booking.id)}
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                        </td>
                        <td className="px-2 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-900">{booking.date.toLocaleDateString()}</td>
                        <td className="px-2 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-900">{booking.timeSlot}</td>
                        <td className="px-2 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-center">{booking.numberOfStudents}</td>
                        <td className="px-2 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                          <input
                            type="number"
                            value={bookingPrices[booking.id] || 0}
                            onChange={(e) => handlePriceChange(booking.id, parseInt(e.target.value))}
                            className="w-20 sm:w-24 p-1 border border-gray-300 rounded-md text-right"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="mt-8 bg-gradient-to-r from-blue-500 to-blue-600 p-4 sm:p-6 rounded-lg shadow-lg">
                <h5 className="text-xl font-bold mb-4 text-white">Oversigt over valgte bookinger</h5>
                <div className="flex flex-col sm:flex-row justify-between items-stretch sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
                  <div className="bg-white p-4 rounded-lg shadow flex-1">
                    <p className="text-gray-600 text-sm mb-1">Samlet antal elever</p>
                    <p className="text-2xl sm:text-3xl font-bold text-gray-800">{calculateSummary().totalStudents}</p>
                  </div>
                  <div className="bg-white p-4 rounded-lg shadow flex-1">
                    <p className="text-gray-600 text-sm mb-1">Samlet pris</p>
                    <p className="text-2xl sm:text-3xl font-bold text-gray-800">{calculateSummary().totalPrice} DKK</p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col sm:flex-row justify-end space-y-4 sm:space-y-0 sm:space-x-4 mt-8">
                <button
                  onClick={() => setShowPopup(false)}
                  className="text-gray-600 hover:text-gray-900 px-3 py-1 bg-gray-100 rounded-full"
                >
                  <FaTimes className="inline mr-1" /> Luk
                </button>
                <button
                  onClick={handleSelectAllBookings}
                  className="text-blue-600 hover:text-blue-900 px-3 py-1 bg-blue-100 rounded-full"
                >
                  <FaCheck className="inline mr-1" /> Vælg alle
                </button>
                <button
                  onClick={() => {
                    setShowPopup(false);
                  }}
                  className="text-green-600 hover:text-green-900 px-3 py-1 bg-green-100 rounded-full"
                >
                  <FaEnvelope className="inline mr-1" /> Send faktura
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Økonomi;