import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, updateDoc, doc, getDoc, setDoc } from 'firebase/firestore';
import { FaEdit, FaCheck, FaTimes, FaSave, FaUser, FaEnvelope, FaBuilding, FaMoneyBillWave, FaSearch } from 'react-icons/fa';

function Brugere() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingUser, setEditingUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('alle');
  const [defaultPrice, setDefaultPrice] = useState(null);
  const [editingDefaultPrice, setEditingDefaultPrice] = useState(false);

  useEffect(() => {
    fetchUsers();
    fetchDefaultPrice();
  }, []);

  useEffect(() => {
    filterUsers();
  }, [searchTerm, filter, users]);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const usersList = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setUsers(usersList);
      setLoading(false);
    } catch (error) {
      console.error('Fejl ved hentning af brugere:', error);
      setError('Der opstod en fejl ved hentning af bruger: ' + error.message);
      setLoading(false);
    }
  };

  const filterUsers = () => {
    let filtered = users.filter(user =>
      user.koereskole.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      `${user.fornavn} ${user.efternavn}`.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (filter === 'godkendt') {
      filtered = filtered.filter(user => user.isApproved);
    } else if (filter === 'ikke-godkendt') {
      filtered = filtered.filter(user => !user.isApproved);
    }

    setFilteredUsers(filtered);
  };

  const updateUser = async (userId, updatedData) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, updatedData);
      fetchUsers();
      setEditingUser(null);
    } catch (error) {
      console.error('Fejl ved opdatering af bruger:', error);
      setError('Der opstod en fejl ved opdatering af bruger: ' + error.message);
    }
  };

  const handleEdit = (user) => {
    setEditingUser({ ...user });
  };

  const handleUpdate = () => {
    updateUser(editingUser.id, editingUser);
  };

  const handleApproval = () => {
    if (editingUser.role === 'admin') {
      alert('Kan ikke fjerne godkendelse fra en admin-bruger.');
      return;
    }
    setEditingUser({ ...editingUser, isApproved: !editingUser.isApproved });
  };

  // Opdater denne funktion til at håndtere 0 som en gyldig værdi
  const handlePriceChange = (e) => {
    const value = e.target.value;
    if (value === '' || !isNaN(value)) {
      setEditingUser({...editingUser, pricePerStudent: value === '' ? null : parseInt(value)});
    }
  };

  const fetchDefaultPrice = async () => {
    try {
      const defaultPriceDoc = await getDoc(doc(db, 'settings', 'defaultPrice'));
      if (defaultPriceDoc.exists()) {
        setDefaultPrice(defaultPriceDoc.data().price);
      } else {
        // Hvis standardprisen ikke eksisterer, opret den med en standardværdi
        await setDoc(doc(db, 'settings', 'defaultPrice'), { price: 0 });
        setDefaultPrice(0);
      }
    } catch (error) {
      console.error('Fejl ved hentning af standardpris:', error);
    }
  };

  const handleDefaultPriceChange = (e) => {
    const value = e.target.value;
    if (value === '' || !isNaN(value)) {
      setDefaultPrice(value === '' ? '' : parseInt(value));
    }
  };

  const saveDefaultPrice = async () => {
    try {
      await setDoc(doc(db, 'settings', 'defaultPrice'), { price: defaultPrice });
      setEditingDefaultPrice(false);
    } catch (error) {
      console.error('Fejl ved gemning af standardpris:', error);
    }
  };

  // Funktion til at vise den korrekte pris
  const displayPrice = (user) => {
    if (user.pricePerStudent === 0) {
      return '0 DKK';
    } else if (user.pricePerStudent) {
      return `${user.pricePerStudent} DKK`;
    } else {
      return defaultPrice !== null ? `${defaultPrice} DKK (standard)` : 'Ikke angivet';
    }
  };

  return (
    <div className="brugere p-4 sm:p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl sm:text-4xl font-extrabold mb-6 sm:mb-8 text-center text-gray-800">Brugere</h2>
      
      {/* Standardpris sektion */}
      <div className="max-w-7xl mx-auto mb-6 bg-white rounded-lg shadow-lg p-4">
        <h3 className="text-xl font-bold mb-4">Standardpris pr. elev</h3>
        {editingDefaultPrice ? (
          <div className="flex items-center">
            <input
              type="number"
              value={defaultPrice}
              onChange={handleDefaultPriceChange}
              className="p-2 border rounded mr-2"
              placeholder="Standardpris"
              min="0"
            />
            <button
              onClick={saveDefaultPrice}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Gem
            </button>
          </div>
        ) : (
          <div className="flex items-center justify-between">
            <span>{defaultPrice !== null ? `${defaultPrice} DKK` : 'Ikke angivet'}</span>
            <button
              onClick={() => setEditingDefaultPrice(true)}
              className="text-blue-600 hover:text-blue-900 px-3 py-1 bg-blue-100 rounded-full"
            >
              <FaEdit className="inline mr-1" /> Rediger
            </button>
          </div>
        )}
      </div>

      {/* Søgefelt og filter */}
      <div className="max-w-7xl mx-auto mb-6">
        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
          <div className="relative flex-grow">
            <input
              type="text"
              placeholder="Søg efter køreskole, email eller navn..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-3 pl-10 pr-4 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="p-3 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="alle">Alle</option>
            <option value="godkendt">Godkendt</option>
            <option value="ikke-godkendt">Ikke godkendt</option>
          </select>
        </div>
      </div>

      {loading && <p className="text-gray-600 text-center text-xl">Indlæser brugere...</p>}
      {error && <p className="text-red-500 bg-red-100 p-4 rounded-lg text-center max-w-2xl mx-auto">{error}</p>}
      {!loading && !error && filteredUsers.length === 0 && <p className="text-gray-600 text-center text-xl">Ingen brugere fundet.</p>}
      
      {!loading && !error && filteredUsers.length > 0 && (
        <div className="max-w-7xl mx-auto">
          <div className="hidden sm:block"> {/* Desktop version */}
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <table className="w-full">
                <thead className="bg-gray-800 text-white">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Køreskole</th>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Email</th>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">CVR</th>
                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Adresse</th>
                    <th className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider">Godkendt</th>
                    <th className="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider">Pris pr. elev</th>
                    <th className="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider">Handling</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {filteredUsers.map(user => (
                    <tr key={user.id} className="hover:bg-gray-50 transition-colors duration-200">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.koereskole}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.email}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.cvr}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{`${user.adresse}, ${user.postnummer} ${user.by}`}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-center">
                        {user.isApproved 
                          ? <span className="text-green-500"><FaCheck className="inline mr-1" /> Ja</span>
                          : <span className="text-red-500"><FaTimes className="inline mr-1" /> Nej</span>
                        }
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-right">
                        {displayPrice(user)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-right">
                        <button
                          onClick={() => handleEdit(user)}
                          className="text-blue-600 hover:text-blue-900 px-3 py-1 bg-blue-100 rounded-full"
                        >
                          <FaEdit className="inline mr-1" /> Rediger
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="sm:hidden"> {/* Mobile version */}
            <div className="space-y-4">
              {filteredUsers.map(user => (
                <div key={user.id} className="bg-white rounded-lg shadow-lg p-4">
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex items-center">
                      <span className="font-medium text-gray-900">{user.koereskole}</span>
                    </div>
                    <span className={user.isApproved ? "text-green-500" : "text-red-500"}>
                      {user.isApproved ? <FaCheck /> : <FaTimes />}
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="text-sm text-gray-600">Email: </span>
                    <span className="text-sm font-medium">{user.email}</span>
                  </div>
                  <div className="mb-2">
                    <span className="text-sm text-gray-600">CVR: </span>
                    <span className="text-sm font-medium">{user.cvr}</span>
                  </div>
                  <div className="mb-2">
                    <span className="text-sm text-gray-600">Adresse: </span>
                    <span className="text-sm font-medium">{`${user.adresse}, ${user.postnummer} ${user.by}`}</span>
                  </div>
                  <div className="mb-4">
                    <span className="text-sm text-gray-600">Pris pr. elev: </span>
                    <span className="text-sm font-medium">
                      {displayPrice(user)}
                    </span>
                  </div>
                  <div className="flex justify-center">
                    <button
                      onClick={() => handleEdit(user)}
                      className="text-blue-600 hover:text-blue-900 px-3 py-1 bg-blue-100 rounded-full"
                    >
                      <FaEdit className="inline mr-1" /> Rediger
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {editingUser && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full">
            <h3 className="text-xl font-bold mb-4 text-gray-800">Rediger bruger</h3>
            <div className="space-y-4">
              <input
                type="text"
                value={editingUser.fornavn}
                onChange={(e) => setEditingUser({...editingUser, fornavn: e.target.value})}
                className="w-full p-2 border rounded"
                placeholder="Fornavn"
              />
              <input
                type="text"
                value={editingUser.efternavn}
                onChange={(e) => setEditingUser({...editingUser, efternavn: e.target.value})}
                className="w-full p-2 border rounded"
                placeholder="Efternavn"
              />
              <input
                type="email"
                value={editingUser.email}
                onChange={(e) => setEditingUser({...editingUser, email: e.target.value})}
                className="w-full p-2 border rounded"
                placeholder="Email"
              />
              <input
                type="text"
                value={editingUser.koereskole}
                onChange={(e) => setEditingUser({...editingUser, koereskole: e.target.value})}
                className="w-full p-2 border rounded"
                placeholder="Køreskole"
              />
              <input
                type="text"
                value={editingUser.cvr}
                onChange={(e) => setEditingUser({...editingUser, cvr: e.target.value})}
                className="w-full p-2 border rounded"
                placeholder="CVR"
              />
              <input
                type="text"
                value={editingUser.adresse}
                onChange={(e) => setEditingUser({...editingUser, adresse: e.target.value})}
                className="w-full p-2 border rounded"
                placeholder="Adresse"
              />
              <input
                type="text"
                value={editingUser.by}
                onChange={(e) => setEditingUser({...editingUser, by: e.target.value})}
                className="w-full p-2 border rounded"
                placeholder="By"
              />
              <input
                type="text"
                value={editingUser.postnummer}
                onChange={(e) => setEditingUser({...editingUser, postnummer: e.target.value})}
                className="w-full p-2 border rounded"
                placeholder="Postnummer"
              />
              <div>
                <label htmlFor="pricePerStudent" className="block text-sm font-medium text-gray-700 mb-1">
                  Pris pr. elev (DKK)
                </label>
                <input
                  id="pricePerStudent"
                  type="number"
                  value={editingUser.pricePerStudent}
                  onChange={handlePriceChange}
                  className="w-full p-2 border rounded"
                  placeholder="Pris pr. elev"
                  min="0"
                />
              </div>
              <div className="flex items-center justify-between mt-4">
                <span className="text-gray-700 font-semibold">Godkendelsesstatus:</span>
                {editingUser.role === 'admin' ? (
                  <span className="text-green-500">Admin (altid godkendt)</span>
                ) : (
                  <button 
                    onClick={handleApproval}
                    className={`px-4 py-2 rounded-lg text-white transition duration-300 ${
                      editingUser.isApproved 
                        ? 'bg-red-500 hover:bg-red-600' 
                        : 'bg-green-500 hover:bg-green-600'
                    }`}
                  >
                    {editingUser.isApproved ? 'Fjern godkendelse' : 'Godkend bruger'}
                  </button>
                )}
              </div>
            </div>
            <div className="flex justify-end space-x-4 mt-8">
              <button
                onClick={() => setEditingUser(null)}
                className="text-gray-600 hover:text-gray-900 px-3 py-1 bg-gray-100 rounded-full"
              >
                <FaTimes className="inline mr-1" /> Annuller
              </button>
              <button
                onClick={handleUpdate}
                className="text-blue-600 hover:text-blue-900 px-3 py-1 bg-blue-100 rounded-full"
              >
                <FaSave className="inline mr-1" /> Gem ændringer
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Brugere;