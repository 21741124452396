import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { FaCalendarAlt } from 'react-icons/fa';

function OffentligeNyheder() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      const newsCollection = collection(db, 'news');
      const newsQuery = query(newsCollection, orderBy('createdAt', 'desc'));
      const newsSnapshot = await getDocs(newsQuery);
      const newsList = newsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate()
      }));
      setNews(newsList);
    } catch (error) {
      console.error('Fejl ved hentning af nyheder:', error);
    }
  };

  return (
    <div className="offentlige-nyheder bg-gray-100 min-h-screen p-4 sm:p-6">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl sm:text-4xl font-bold mb-8 text-center text-gray-800">Nyheder</h1>
        <div className="space-y-6">
          {news.map(article => (
            <article key={article.id} className="bg-white rounded-lg shadow-md overflow-hidden">
              <div className="p-6">
                <h2 className="text-2xl font-semibold mb-2 text-gray-800">{article.title}</h2>
                <div className="flex items-center text-gray-600 mb-4">
                  <FaCalendarAlt className="mr-2" />
                  <span>{article.createdAt?.toLocaleDateString('da-DK')}</span>
                </div>
                <p className="text-gray-700 leading-relaxed">{article.content}</p>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OffentligeNyheder;
