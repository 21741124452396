import React from 'react';
import { FaFacebookF, FaEnvelope, FaPhone } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="bg-gray-50 text-gray-800 py-8 shadow-lg border-t border-gray-200">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
          <div className="w-full md:w-1/3 flex flex-col items-center md:items-start">
            <h3 className="text-2xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black">Kontakt os</h3>
            <div className="flex items-center mb-2 hover:text-gray-600 transition duration-300">
              <FaEnvelope className="mr-2" />
              <a href="mailto:mx@mail.dk">mx@mail.dk</a>
            </div>
            <div className="flex items-center mb-2 hover:text-gray-600 transition duration-300">
              <FaPhone className="mr-2" />
              <a href="tel:+4540961230">+45 40 96 12 30</a>
            </div>
            <p className="text-gray-600">CVR: 35050957</p>
          </div>
          
          <div className="w-full md:w-1/3 text-center">
            <p className="text-lg">
              Skabt af{' '}
              <a 
                href="https://www.parlabranding.dk/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="font-semibold hover:underline transition duration-300"
              >
                Parla Branding
              </a>
            </p>
          </div>
          
          <div className="w-full md:w-1/3 flex justify-center md:justify-end">
            <a 
              href="https://www.facebook.com/p/Gislinge-Man%C3%B8vrebane-Aps-100067407934059/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="bg-black text-white rounded-lg p-4 transition duration-300 transform hover:scale-110 hover:bg-gray-800"
            >
              <FaFacebookF size={24} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;