import React from 'react';
import { Navigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function PrivateRoute({ children, requiredRole = 'user' }) {
  const { currentUser } = useAuth();

  if (!currentUser) {
    return (
      <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 text-center">
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            Adgang nægtet
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Log ind eller opret en bruger for at få adgang.
          </p>
          <div className="mt-6 flex justify-center space-x-4">
            <Link
              to="/login"
              className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
            >
              Log ind
            </Link>
            <Link
              to="/tilmeld"
              className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
            >
              Tilmeld
            </Link>
          </div>
        </div>
      </div>
    );
  }

  if (requiredRole === 'admin' && currentUser.role !== 'admin') {
    return <Navigate to="/" />;
  }

  if (requiredRole === 'approved' && !currentUser.isApproved && currentUser.role !== 'admin') {
    return (
      <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 text-center">
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            Adgang nægtet
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Kontakt en administrator for at få adgang.
          </p>
          <div className="mt-6">
            <Link
              to="/"
              className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
            >
              Tilbage til forsiden
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return children;
}

export default PrivateRoute;
