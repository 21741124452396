import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc, serverTimestamp, query, orderBy } from 'firebase/firestore';
import { FaPlus, FaEdit, FaTrash, FaSave, FaTimes, FaSearch } from 'react-icons/fa';

function Nyheder() {
  const [news, setNews] = useState([]);
  const [filteredNews, setFilteredNews] = useState([]);
  const [newArticle, setNewArticle] = useState({ title: '', content: '' });
  const [editingArticle, setEditingArticle] = useState(null);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchNews();
  }, []);

  useEffect(() => {
    filterNews();
  }, [searchTerm, news]);

  const fetchNews = async () => {
    try {
      const newsCollection = collection(db, 'news');
      const newsQuery = query(newsCollection, orderBy('createdAt', 'desc'));
      const newsSnapshot = await getDocs(newsQuery);
      const newsList = newsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate()
      }));
      setNews(newsList);
    } catch (error) {
      console.error('Fejl ved hentning af nyheder:', error);
    }
  };

  const filterNews = () => {
    const filtered = news.filter(article =>
      article.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredNews(filtered);
  };

  const addNews = async () => {
    try {
      await addDoc(collection(db, 'news'), {
        ...newArticle,
        createdAt: serverTimestamp()
      });
      setNewArticle({ title: '', content: '' });
      setShowAddPopup(false);
      fetchNews();
    } catch (error) {
      console.error('Fejl ved tilføjelse af nyhed:', error);
    }
  };

  const updateNews = async (id, updatedData) => {
    try {
      const newsRef = doc(db, 'news', id);
      await updateDoc(newsRef, updatedData);
      setEditingArticle(null);
      fetchNews();
    } catch (error) {
      console.error('Fejl ved opdatering af nyhed:', error);
    }
  };

  const deleteNews = async (id) => {
    if (window.confirm('Er du sikker på, at du vil slette denne nyhed?')) {
      try {
        await deleteDoc(doc(db, 'news', id));
        fetchNews();
      } catch (error) {
        console.error('Fejl ved sletning af nyhed:', error);
      }
    }
  };

  return (
    <div className="nyheder p-4 sm:p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl sm:text-4xl font-extrabold mb-6 sm:mb-8 text-center text-gray-800">Nyheder</h2>
      
      <div className="max-w-7xl mx-auto mb-6">
        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
          <div className="relative flex-grow">
            <input
              type="text"
              placeholder="Søg efter nyhedstitel..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-3 pl-10 pr-4 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
          <button
            onClick={() => setShowAddPopup(true)}
            className="px-6 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center"
          >
            <FaPlus className="mr-2" />
            Tilføj nyhed
          </button>
        </div>
      </div>

      <div className="max-w-7xl mx-auto">
        <div className="hidden sm:block"> {/* Desktop version */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <table className="w-full">
              <thead className="bg-gray-800 text-white">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Titel</th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Indhold</th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Dato</th>
                  <th className="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider">Handlinger</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredNews.map(article => (
                  <tr key={article.id} className="hover:bg-gray-50 transition-colors duration-200">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">{article.title}</div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">{article.content.substring(0, 100)}...</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">{article.createdAt?.toLocaleDateString('da-DK')}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        onClick={() => setEditingArticle(article)}
                        className="text-indigo-600 hover:text-indigo-900 mr-2 px-3 py-1 bg-indigo-100 rounded-full"
                      >
                        <FaEdit className="inline mr-1" /> Rediger
                      </button>
                      <button
                        onClick={() => deleteNews(article.id)}
                        className="text-red-600 hover:text-red-900 px-3 py-1 bg-red-100 rounded-full"
                      >
                        <FaTrash className="inline mr-1" /> Slet
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="sm:hidden"> {/* Mobile version */}
          <div className="space-y-4">
            {filteredNews.map(article => (
              <div key={article.id} className="bg-white rounded-lg shadow-lg p-4">
                <div className="mb-2">
                  <span className="font-medium text-gray-900">{article.title}</span>
                </div>
                <div className="mb-2">
                  <span className="text-sm text-gray-500">{article.content.substring(0, 100)}...</span>
                </div>
                <div className="mb-4">
                  <span className="text-sm text-gray-600">Dato: </span>
                  <span className="text-sm">{article.createdAt?.toLocaleDateString('da-DK')}</span>
                </div>
                <div className="flex justify-center space-x-2">
                  <button
                    onClick={() => setEditingArticle(article)}
                    className="text-indigo-600 hover:text-indigo-900 px-3 py-1 bg-indigo-100 rounded-full"
                  >
                    <FaEdit className="inline mr-1" /> Rediger
                  </button>
                  <button
                    onClick={() => deleteNews(article.id)}
                    className="text-red-600 hover:text-red-900 px-3 py-1 bg-red-100 rounded-full"
                  >
                    <FaTrash className="inline mr-1" /> Slet
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {showAddPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg max-w-2xl w-full shadow-xl">
            <h3 className="text-3xl font-extrabold mb-6 text-center text-gray-800">Tilføj ny nyhed</h3>
            <div className="mb-6">
              <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-2">Titel</label>
              <input 
                id="title"
                type="text" 
                value={newArticle.title} 
                onChange={(e) => setNewArticle({...newArticle, title: e.target.value})}
                placeholder="Indtast nyhedens titel"
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
              />
            </div>
            <div className="mb-6">
              <label htmlFor="content" className="block text-sm font-medium text-gray-700 mb-2">Indhold</label>
              <textarea 
                id="content"
                value={newArticle.content} 
                onChange={(e) => setNewArticle({...newArticle, content: e.target.value})}
                placeholder="Skriv nyhedens indhold her"
                className="w-full p-3 border border-gray-300 rounded-md h-40 resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowAddPopup(false)}
                className="px-6 py-2 bg-gray-300 text-gray-800 rounded-full hover:bg-gray-400 transition-colors duration-200 flex items-center"
              >
                <FaTimes className="mr-2" />
                Annuller
              </button>
              <button 
                onClick={addNews}
                className="px-6 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-200 flex items-center"
              >
                <FaPlus className="mr-2" />
                Tilføj nyhed
              </button>
            </div>
          </div>
        </div>
      )}

      {editingArticle && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg max-w-2xl w-full shadow-xl">
            <h3 className="text-3xl font-extrabold mb-6 text-center text-gray-800">Rediger nyhed</h3>
            <div className="mb-6">
              <label htmlFor="edit-title" className="block text-sm font-medium text-gray-700 mb-2">Titel</label>
              <input 
                id="edit-title"
                type="text" 
                value={editingArticle.title} 
                onChange={(e) => setEditingArticle({...editingArticle, title: e.target.value})}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
              />
            </div>
            <div className="mb-6">
              <label htmlFor="edit-content" className="block text-sm font-medium text-gray-700 mb-2">Indhold</label>
              <textarea 
                id="edit-content"
                value={editingArticle.content} 
                onChange={(e) => setEditingArticle({...editingArticle, content: e.target.value})}
                className="w-full p-3 border border-gray-300 rounded-md h-40 resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button 
                onClick={() => setEditingArticle(null)}
                className="px-6 py-2 bg-gray-300 text-gray-800 rounded-full hover:bg-gray-400 transition-colors duration-200 flex items-center"
              >
                <FaTimes className="mr-2" />
                Annuller
              </button>
              <button 
                onClick={() => updateNews(editingArticle.id, { title: editingArticle.title, content: editingArticle.content })}
                className="px-6 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-200 flex items-center"
              >
                <FaSave className="mr-2" />
                Gem ændringer
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Nyheder;
