import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { FaEnvelope, FaSpinner } from 'react-icons/fa';

function Mail() {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const usersCollection = collection(db, 'users');
      const userSnapshot = await getDocs(usersCollection);
      const userList = userSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setUsers(userList);
    } catch (error) {
      console.error('Fejl ved hentning af brugere:', error);
    }
  };

  const handleUserSelection = (userId) => {
    setSelectedUsers(prevSelected => 
      prevSelected.includes(userId)
        ? prevSelected.filter(id => id !== userId)
        : [...prevSelected, userId]
    );
  };

  const handleSelectAll = () => {
    setSelectedUsers(users.map(user => user.id));
  };

  const handleDeselectAll = () => {
    setSelectedUsers([]);
  };

  const handleSendMail = async () => {
    if (selectedUsers.length === 0 || !subject || !message) {
      alert('Vælg venligst modtagere og udfyld både emne og besked.');
      return;
    }

    setIsSending(true);

    try {
      const selectedUserEmails = users
        .filter(user => selectedUsers.includes(user.id))
        .map(user => user.email);

      const signature = "\n\nVenlig hilsen\nPeter Jensen\nGislinge Manøvrebane\nTlf. 40 96 12 30";
      const messageWithSignature = message + signature;
      
      const formatteredMessage = messageWithSignature.replace(/\n/g, '<br />');

      for (const email of selectedUserEmails) {
        await addDoc(collection(db, 'mail'), {
          to: email,
          message: {
            subject: subject,
            html: formatteredMessage
          }
        });
      }

      alert('E-mail-anmodninger er blevet oprettet og vil blive sendt snart.');
      setSubject('');
      setMessage('');
      setSelectedUsers([]);
    } catch (error) {
      console.error('Fejl ved oprettelse af e-mail-anmodninger:', error);
      alert('Der opstod en fejl ved oprettelse af e-mail-anmodninger. Prøv venligst igen.');
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">Send Bulk E-mail</h2>
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Vælg modtagere:</h3>
        <div className="flex space-x-2 mb-2">
          <button onClick={handleSelectAll} className="bg-blue-500 text-white px-3 py-1 rounded">Vælg alle</button>
          <button onClick={handleDeselectAll} className="bg-gray-500 text-white px-3 py-1 rounded">Fravælg alle</button>
        </div>
        <div className="max-h-40 overflow-y-auto border p-2">
          {users.map(user => (
            <div key={user.id} className="flex items-center mb-2">
              <input
                type="checkbox"
                id={user.id}
                checked={selectedUsers.includes(user.id)}
                onChange={() => handleUserSelection(user.id)}
                className="mr-2"
              />
              <label htmlFor={user.id}>{user.email}</label>
            </div>
          ))}
        </div>
      </div>
      <div className="mb-4">
        <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">Emne:</label>
        <input
          type="text"
          id="subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className="w-full p-2 border rounded"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">Besked:</label>
        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="w-full p-2 border rounded h-40"
        ></textarea>
      </div>
      <button
        onClick={handleSendMail}
        disabled={isSending}
        className={`flex items-center justify-center w-full bg-blue-500 text-white px-4 py-2 rounded ${isSending ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
      >
        {isSending ? (
          <>
            <FaSpinner className="animate-spin mr-2" />
            Sender...
          </>
        ) : (
          <>
            <FaEnvelope className="mr-2" />
            Send E-mails
          </>
        )}
      </button>
    </div>
  );
}

export default Mail;
