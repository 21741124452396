import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebase';
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore';
import { FaCalendarAlt, FaClipboardList, FaChevronRight } from 'react-icons/fa';
import gislingembaneHero from '../images/gislingembaneHero.jpg';

function Forside() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      const newsCollection = collection(db, 'news');
      const newsQuery = query(newsCollection, orderBy('createdAt', 'desc'), limit(3));
      const newsSnapshot = await getDocs(newsQuery);
      const newsList = newsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setNews(newsList);
    } catch (error) {
      console.error('Fejl ved hentning af nyheder:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 text-gray-800 overflow-x-hidden">
      <div className="relative">
        <img src={gislingembaneHero} alt="Gislinge Manøvrebane" className="w-full h-[60vh] object-cover" />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="text-center">
            <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-extrabold mb-4 sm:mb-6 tracking-tight text-white">
              Gislinge Manøvrebane
            </h1>
            <p className="text-lg sm:text-xl md:text-2xl text-white max-w-3xl mx-auto leading-relaxed px-4">
              Professionel træningsfacilitet for køreskoler. Book baner og administrer dine tider nemt og effektivt.
            </p>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-8 sm:py-16">

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16 max-w-6xl mx-auto">
          <div className="space-y-6 sm:space-y-8">
            <h2 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 border-b-2 border-gray-200 pb-2">Hurtig adgang</h2>
            <Link to="/bestil-bane" className="flex items-center justify-between w-full bg-black text-white py-4 sm:py-6 px-6 sm:px-8 rounded-lg hover:bg-gray-800 transition duration-300 text-lg sm:text-xl font-medium shadow-lg hover:shadow-xl transform hover:-translate-y-1">
              <span>Bestil bane</span>
              <FaCalendarAlt size={24} />
            </Link>
            <Link to="/bookinger" className="flex items-center justify-between w-full bg-white text-black py-4 sm:py-6 px-6 sm:px-8 rounded-lg hover:bg-gray-100 transition duration-300 text-lg sm:text-xl font-medium border-2 border-black shadow-lg hover:shadow-xl transform hover:-translate-y-1">
              <span>Se mine bookinger</span>
              <FaClipboardList size={24} />
            </Link>
          </div>
          <div>
            <h2 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 border-b-2 border-gray-200 pb-2">Seneste nyt</h2>
            <ul className="space-y-6 sm:space-y-8">
              {news.map(article => (
                <li key={article.id} className="bg-white p-4 sm:p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
                  <h3 className="font-bold text-xl sm:text-2xl mb-2 sm:mb-3">{article.title}</h3>
                  <p className="text-gray-600 mb-3 sm:mb-4">{article.content}</p>
                  <Link to="/nyheder" className="text-black font-medium hover:underline inline-flex items-center">
                    Læs mere <FaChevronRight className="ml-2" />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forside;
