import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { FaEdit, FaTrash, FaCheck, FaTimes, FaUsers, FaCalendarAlt, FaClock, FaSchool, FaSearch } from 'react-icons/fa';

function AdminBookings() {
  const [bookings, setBookings] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [editingBooking, setEditingBooking] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchBookings();
  }, []);

  useEffect(() => {
    filterBookings();
  }, [searchTerm, bookings]);

  const fetchBookings = async () => {
    try {
      const bookingsCollection = collection(db, 'bookings');
      const bookingsSnapshot = await getDocs(bookingsCollection);
      const bookingsList = bookingsSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          date: data.date.toDate(),
          time: data.timeSlot,
          studentCount: data.numberOfStudents,
          drivingSchool: data.koereskole,
          invoiced: data.invoiced || false
        };
      });
      // Sorter bookings efter dato, seneste først
      const sortedBookings = bookingsList.sort((a, b) => b.date - a.date);
      setBookings(sortedBookings);
    } catch (error) {
      console.error('Fejl ved hentning af bookinger:', error);
    }
  };

  const filterBookings = () => {
    const filtered = bookings.filter(booking =>
      booking.drivingSchool.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredBookings(filtered);
  };

  const handleEdit = (booking) => {
    setEditingBooking(booking);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Er du sikker på, at du vil slette denne booking?')) {
      try {
        await deleteDoc(doc(db, 'bookings', id));
        fetchBookings();
      } catch (error) {
        console.error('Fejl ved sletning af booking:', error);
      }
    }
  };

  const handleSave = async () => {
    try {
      const { id, studentCount } = editingBooking;
      await updateDoc(doc(db, 'bookings', id), {
        numberOfStudents: parseInt(studentCount)
      });
      setEditingBooking(null);
      fetchBookings();
    } catch (error) {
      console.error('Fejl ved opdatering af booking:', error);
    }
  };

  const isPastDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
  };

  return (
    <div className="admin-bookings p-4 sm:p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl sm:text-4xl font-extrabold mb-6 sm:mb-8 text-center text-gray-800">Administrer Bookinger</h2>
      
      <div className="max-w-7xl mx-auto mb-6">
        <div className="relative">
          <input
            type="text"
            placeholder="Søg efter køreskole..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-3 pl-10 pr-4 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        </div>
      </div>

      <div className="max-w-7xl mx-auto">
        <div className="hidden sm:block"> {/* Desktop version */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <table className="w-full table-auto">
              <thead className="bg-gray-800 text-white">
                <tr>
                  <th className="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">Dato</th>
                  <th className="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">Tid</th>
                  <th className="px-4 py-2 text-center text-xs font-medium uppercase tracking-wider">Elever</th>
                  <th className="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">Køreskole</th>
                  <th className="px-4 py-2 text-center text-xs font-medium uppercase tracking-wider">Status</th>
                  <th className="px-4 py-2 text-center text-xs font-medium uppercase tracking-wider">Faktureret</th>
                  <th className="px-4 py-2 text-center text-xs font-medium uppercase tracking-wider">Køretøj</th>
                  <th className="px-4 py-2 text-center text-xs font-medium uppercase tracking-wider">Handlinger</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredBookings.map(booking => (
                  <tr key={booking.id} className="hover:bg-gray-50 transition-colors duration-200">
                    <td className="px-4 py-2 whitespace-nowrap text-sm">{booking.date.toLocaleDateString('da-DK')}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm">{booking.time}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-center">{booking.studentCount}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm truncate max-w-xs">{booking.drivingSchool}</td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-center">
                      {isPastDate(booking.date) ? (
                        <span className="text-red-500">Tidligere dato</span>
                      ) : (
                        <span className="text-green-500">Kommende</span>
                      )}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-center">
                      {booking.invoiced ? (
                        <FaCheck className="inline-block text-green-500" />
                      ) : (
                        <FaTimes className="inline-block text-red-500" />
                      )}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-center">
                      {booking.vehicleType === 'bil' ? 'Bil' : 'Motorcykel'}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-sm text-center">
                      <button onClick={() => handleEdit(booking)} className="text-blue-600 hover:text-blue-900 px-3 py-1 bg-blue-100 rounded-full mr-2">
                        <FaEdit className="inline mr-1" /> Rediger
                      </button>
                      <button onClick={() => handleDelete(booking.id)} className="text-red-600 hover:text-red-900 px-3 py-1 bg-red-100 rounded-full">
                        <FaTrash className="inline mr-1" /> Slet
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="sm:hidden"> {/* Mobile version */}
          <div className="space-y-4">
            {filteredBookings.map(booking => (
              <div key={booking.id} className="bg-white rounded-lg shadow-lg p-4">
                <div className="flex justify-between items-center mb-2">
                  <div className="flex items-center">
                    <FaCalendarAlt className="text-gray-500 mr-2" />
                    <span className="font-medium">{booking.date.toLocaleDateString('da-DK')}</span>
                  </div>
                  <div className="flex items-center">
                    <FaClock className="text-gray-500 mr-2" />
                    <span>{booking.time}</span>
                  </div>
                </div>
                <div className="flex justify-between items-center mb-2">
                  <div className="flex items-center">
                    <FaUsers className="text-gray-500 mr-2" />
                    <span>{booking.studentCount} elever</span>
                  </div>
                  <div className="flex items-center">
                    <FaSchool className="text-gray-500 mr-2" />
                    <span className="truncate max-w-[150px]">{booking.drivingSchool}</span>
                  </div>
                </div>
                <div className="flex justify-between items-center mb-2">
                  <span className={isPastDate(booking.date) ? "text-red-500" : "text-green-500"}>
                    {isPastDate(booking.date) ? "Tidligere dato" : "Kommende"}
                  </span>
                  <span>
                    Faktureret: {booking.invoiced ? 
                      <FaCheck className="inline-block text-green-500" /> : 
                      <FaTimes className="inline-block text-red-500" />
                    }
                  </span>
                </div>
                <div className="flex justify-between items-center mb-2">
                  <div className="flex items-center">
                    <span className="font-medium">Køretøj:</span>
                    <span className="ml-2">{booking.vehicleType === 'bil' ? 'Bil' : 'Motorcykel'}</span>
                  </div>
                </div>
                <div className="flex flex-wrap justify-center gap-2 mt-4">
                  <button onClick={() => handleEdit(booking)} className="text-blue-600 hover:text-blue-900 px-3 py-1 bg-blue-100 rounded-full">
                    <FaEdit className="inline mr-1" /> Rediger
                  </button>
                  <button onClick={() => handleDelete(booking.id)} className="text-red-600 hover:text-red-900 px-3 py-1 bg-red-100 rounded-full">
                    <FaTrash className="inline mr-1" /> Slet
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {editingBooking && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full">
            <h3 className="text-xl font-bold mb-4 text-gray-800">Rediger Booking</h3>
            <div className="space-y-4">
              <div>
                <p className="text-sm font-medium text-gray-700 mb-1">Dato</p>
                <p className="p-2 bg-gray-100 rounded-md text-gray-800">{editingBooking.date.toLocaleDateString('da-DK')}</p>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-700 mb-1">Tidspunkt</p>
                <p className="p-2 bg-gray-100 rounded-md text-gray-800">{editingBooking.time}</p>
              </div>
              <div>
                <label htmlFor="students" className="block text-sm font-medium text-gray-700 mb-1">Antal elever</label>
                <div className="relative">
                  <select
                    id="students"
                    value={editingBooking.studentCount}
                    onChange={(e) => setEditingBooking({...editingBooking, studentCount: parseInt(e.target.value)})}
                    className="w-full p-2 pl-8 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                  <FaUsers className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                </div>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-700 mb-1">Køreskole</p>
                <p className="p-2 bg-gray-100 rounded-md text-gray-800">{editingBooking.drivingSchool}</p>
              </div>
            </div>
            <div className="flex justify-end space-x-4 mt-6">
              <button
                onClick={() => setEditingBooking(null)}
                className="text-gray-600 hover:text-gray-900 px-3 py-1 bg-gray-100 rounded-full"
              >
                <FaTimes className="inline mr-1" /> Annuller
              </button>
              <button
                onClick={handleSave}
                className="text-blue-600 hover:text-blue-900 px-3 py-1 bg-blue-100 rounded-full"
              >
                <FaCheck className="inline mr-1" /> Gem ændringer
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminBookings;