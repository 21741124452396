import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, where, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { FaEdit, FaTrash, FaCalendarAlt, FaClock, FaUsers, FaCar, FaMotorcycle } from 'react-icons/fa';

function Bookinger({ isAdmin = false }) {
  const [bookings, setBookings] = useState([]);
  const [editingBooking, setEditingBooking] = useState(null);
  const [numberOfStudents, setNumberOfStudents] = useState(1);
  const { currentUser } = useAuth();
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [vehicleType, setVehicleType] = useState('bil');

  useEffect(() => {
    if (currentUser) {
      fetchBookings();
    }
  }, [currentUser]);

  const fetchBookings = async () => {
    try {
      const bookingsRef = collection(db, 'bookings');
      const q = isAdmin ? bookingsRef : query(bookingsRef, where("userId", "==", currentUser.uid));
      const querySnapshot = await getDocs(q);
      const bookingsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date.toDate()
      }));
      
      // Sorter bookings
      const sortedBookings = bookingsList.sort((a, b) => {
        const now = new Date();
        const aIsPast = a.date < now;
        const bIsPast = b.date < now;
        
        if (aIsPast && !bIsPast) return 1;
        if (!aIsPast && bIsPast) return -1;
        return a.date - b.date;
      });
      
      setBookings(sortedBookings);
    } catch (error) {
      console.error('Fejl ved hentning af bookinger:', error);
    }
  };

  const handleDelete = (bookingId) => {
    setConfirmDelete(bookingId);
  };

  const confirmDeleteBooking = async () => {
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, 'bookings', confirmDelete));
        setConfirmDelete(null);
        fetchBookings();
      } catch (error) {
        console.error('Fejl ved sletning af booking:', error);
        alert('Der opstod en fejl ved sletning. Prøv venligst igen.');
      }
    }
  };

  const handleEdit = (booking) => {
    if (isBookingInPast(booking)) {
      alert('Du kan ikke redigere en booking fra tidligere.');
      return;
    }
    setEditingBooking(booking);
    setNumberOfStudents(booking.numberOfStudents);
    setVehicleType(booking.vehicleType);
  };

  const handleUpdate = async () => {
    try {
      await updateDoc(doc(db, 'bookings', editingBooking.id), {
        numberOfStudents,
        vehicleType,
        updatedAt: new Date()
      });
      setEditingBooking(null);
      fetchBookings();
    } catch (error) {
      console.error('Fejl ved opdatering af booking:', error);
      alert('Der opstod en fejl ved opdatering. Prøv venligst igen.');
    }
  };

  const isBookingInPast = (booking) => {
    const now = new Date();
    return booking.date < now;
  };

  return (
    <div className="p-4 sm:p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl sm:text-4xl font-extrabold mb-6 sm:mb-8 text-center text-gray-800">Mine Bookinger</h2>
      <div className="max-w-6xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="px-4 py-3 sm:py-4 text-left text-xs font-semibold uppercase tracking-wider">Dato</th>
                <th className="px-4 py-3 sm:py-4 text-left text-xs font-semibold uppercase tracking-wider">Tid</th>
                <th className="px-4 py-3 sm:py-4 text-center text-xs font-semibold uppercase tracking-wider">Elever</th>
                <th className="px-4 py-3 sm:py-4 text-center text-xs font-semibold uppercase tracking-wider">Køretøj</th>
                <th className="px-4 py-3 sm:py-4 text-right text-xs font-semibold uppercase tracking-wider">Handlinger</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {bookings.map((booking) => {
                const isPast = isBookingInPast(booking);
                return (
                  <tr key={booking.id} className="hover:bg-gray-50 transition-colors duration-200">
                    <td className="px-4 py-3 sm:py-6 whitespace-nowrap">
                      <div className="flex items-center">
                        <FaCalendarAlt className="mr-2 text-gray-500 hidden sm:inline" />
                        <div className="text-sm sm:text-base font-medium text-gray-900">
                          {booking.date.toLocaleDateString('da-DK')}
                        </div>
                      </div>
                    </td>
                    <td className="px-4 py-3 sm:py-6 whitespace-nowrap">
                      <div className="flex items-center">
                        <FaClock className="mr-2 text-gray-500 hidden sm:inline" />
                        <div className="text-sm sm:text-base text-gray-900">{booking.timeSlot}</div>
                      </div>
                    </td>
                    <td className="px-4 py-3 sm:py-6 whitespace-nowrap text-center">
                      <div className="flex items-center justify-center">
                        <FaUsers className="mr-2 text-gray-500 hidden sm:inline" />
                        <div className="text-sm sm:text-base font-medium text-gray-900">{booking.numberOfStudents}</div>
                      </div>
                    </td>
                    <td className="px-4 py-3 sm:py-6 whitespace-nowrap text-center">
                      <div className="flex items-center justify-center">
                        {booking.vehicleType === 'bil' ? <FaCar className="text-gray-500" /> : <FaMotorcycle className="text-gray-500" />}
                        <span className="ml-2 text-sm sm:text-base font-medium text-gray-900">
                          {booking.vehicleType === 'bil' ? 'Bil' : 'MC'}
                        </span>
                      </div>
                    </td>
                    <td className="px-4 py-3 sm:py-6 whitespace-nowrap text-right text-sm sm:text-base font-medium">
                      {!isPast ? (
                        <div className="flex justify-end space-x-2">
                          <button
                            onClick={() => handleEdit(booking)}
                            className="p-2 sm:px-4 sm:py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-200 flex items-center"
                          >
                            <FaEdit className="sm:mr-2" />
                            <span className="hidden sm:inline">Rediger</span>
                          </button>
                          <button
                            onClick={() => handleDelete(booking.id)}
                            className="p-2 sm:px-4 sm:py-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors duration-200 flex items-center sm:flex hidden"
                          >
                            <FaTrash className="sm:mr-2" />
                            <span className="hidden sm:inline">Slet</span>
                          </button>
                        </div>
                      ) : (
                        <span className="text-gray-500">Forbi</span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {editingBooking && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg max-w-md w-full">
            <h3 className="text-2xl font-bold mb-6 text-gray-800">Rediger booking</h3>
            <p className="mb-4">
              <strong>Dato og tid:</strong> {editingBooking.date.toLocaleDateString('da-DK')} {editingBooking.timeSlot}
            </p>
            <div className="mb-6">
              <label htmlFor="students" className="block text-sm font-medium text-gray-700 mb-2">
                Antal elever (maks. 3)
              </label>
              <input
                type="number"
                id="students"
                min="1"
                max="3"
                value={numberOfStudents}
                onChange={(e) => setNumberOfStudents(Math.min(3, Math.max(1, parseInt(e.target.value))))}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="mb-6">
              <label htmlFor="vehicleType" className="block text-sm font-medium text-gray-700 mb-2">
                Køretøjstype
              </label>
              <select
                id="vehicleType"
                value={vehicleType}
                onChange={(e) => setVehicleType(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="bil">Bil</option>
                <option value="motorcykel">Motorcykel</option>
              </select>
            </div>
            <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4">
              <button
                onClick={() => setEditingBooking(null)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-full hover:bg-gray-400 transition-colors duration-200"
              >
                Annuller
              </button>
              <button
                onClick={handleUpdate}
                className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-200"
              >
                Opdater
              </button>
              <button
                onClick={() => {
                  handleDelete(editingBooking.id);
                  setEditingBooking(null);
                }}
                className="px-4 py-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors duration-200 sm:hidden"
              >
                Slet booking
              </button>
            </div>
          </div>
        </div>
      )}

      {confirmDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-sm w-full">
            <h3 className="text-lg font-bold mb-4">Bekræft sletning</h3>
            <p className="mb-4">Er du sikker på, at du vil slette denne booking?</p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={() => setConfirmDelete(null)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-full hover:bg-gray-400 transition-colors duration-200"
              >
                Annuller
              </button>
              <button
                onClick={confirmDeleteBooking}
                className="px-4 py-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors duration-200"
              >
                Slet booking
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Bookinger;