// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDdoXjWQU64dBVPDaXs-kdoYNrOLOFwkDU",
  authDomain: "gislingembane.firebaseapp.com",
  projectId: "gislingembane",
  storageBucket: "gislingembane.appspot.com",
  messagingSenderId: "928407082323",
  appId: "1:928407082323:web:010ea91e1ad4bd15bbfda6",
  measurementId: "G-VZB6YKT90P"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

// Sæt persistence til local (14 dage)
setPersistence(auth, browserLocalPersistence);

export { db, auth };