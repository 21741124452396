import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, where, getDocs, addDoc, deleteDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { FaChevronLeft, FaChevronRight, FaCalendarAlt, FaCar, FaMotorcycle, FaUser, FaSchool } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import da from 'date-fns/locale/da';

function Kalender() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [bookings, setBookings] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [numberOfStudents, setNumberOfStudents] = useState(1);
  const [editingBooking, setEditingBooking] = useState(null);
  const { currentUser } = useAuth();
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [vehicleType, setVehicleType] = useState('bil');
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);

  const timeSlots = [
    "05-08", "08-11", "11-14",
    "14-17", "17-20", "20-23"
  ];

  const [weekStart, setWeekStart] = useState(getMonday(new Date()));

  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6:1);
    return new Date(d.setDate(diff));
  }

  const isDateInPast = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
  };

  const getCurrentWeekStart = () => {
    const today = new Date();
    return getMonday(today);
  };

  const goToPreviousWeek = () => {
    const newWeekStart = new Date(weekStart);
    newWeekStart.setDate(newWeekStart.getDate() - 7);
    const currentWeekStart = getCurrentWeekStart();
    if (newWeekStart >= currentWeekStart) {
      setWeekStart(newWeekStart);
    } else {
      setWeekStart(currentWeekStart);
    }
  };

  const goToNextWeek = () => {
    const newWeekStart = new Date(weekStart);
    newWeekStart.setDate(newWeekStart.getDate() + 7);
    setWeekStart(newWeekStart);
  };

  useEffect(() => {
    console.log('Calling fetchBookings with currentUser:', currentUser?.uid);
    if (currentUser) {
      fetchBookings();
    }
  }, [weekStart, currentUser]);

  useEffect(() => {
    console.log('Bookings updated:', bookings);
  }, [bookings]);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        setIsAdmin(userDoc.data()?.role === 'admin');
      }
    };
    checkAdminStatus();
  }, [currentUser]);

  const fetchBookings = async () => {
    try {
      const startDate = new Date(weekStart);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(weekStart);
      endDate.setDate(endDate.getDate() + 7);
      endDate.setHours(23, 59, 59, 999);

      console.log('Fetching bookings from', startDate, 'to', endDate);

      const bookingsRef = collection(db, 'bookings');
      const q = query(
        bookingsRef,
        where("date", ">=", startDate),
        where("date", "<=", endDate)
      );
      const querySnapshot = await getDocs(q);
      console.log('Query snapshot size:', querySnapshot.size);

      if (querySnapshot.empty) {
        console.log('No bookings found in the date range');
      } else {
        querySnapshot.forEach((doc) => {
          console.log('Document data:', doc.id, '=>', doc.data());
        });
      }

      const fetchedBookings = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          date: data.date.toDate()
        };
      });
      console.log('Processed bookings:', fetchedBookings);
      setBookings(fetchedBookings);
    } catch (error) {
      console.error('Fejl ved hentning af bookinger:', error);
    }
  };

  const handleBooking = async () => {
    if (!selectedSlot || !currentUser) return;

    try {
      const bookingDate = new Date(selectedSlot);
      bookingDate.setHours(parseInt(selectedSlot.getHours()), 0, 0, 0);

      const bookingData = {
        userId: currentUser.uid,
        date: bookingDate,
        timeSlot: `${bookingDate.getHours().toString().padStart(2, '0')}00-${(bookingDate.getHours() + 3).toString().padStart(2, '0')}00`,
        koereskole: currentUser.koereskole,
        numberOfStudents,
        vehicleType,
        createdAt: new Date()
      };

      console.log('Booking data to be saved:', bookingData);

      if (editingBooking) {
        await updateDoc(doc(db, 'bookings', editingBooking.id), {
          numberOfStudents,
          updatedAt: new Date()
        });
        setEditingBooking(null);
      } else {
        await addDoc(collection(db, 'bookings'), bookingData);
      }
      setSelectedSlot(null);
      setNumberOfStudents(1);
      await fetchBookings();
    } catch (error) {
      console.error('Fejl ved booking:', error);
      alert('Der opstod en fejl. Prøv venligst igen.');
    }
  };

  const handleDelete = async (bookingId) => {
    setConfirmDelete(bookingId);
  };

  const confirmDeleteBooking = async () => {
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, 'bookings', confirmDelete));
        await fetchBookings();
        setEditingBooking(null);
        setSelectedSlot(null);
        setConfirmDelete(null);
      } catch (error) {
        console.error('Fejl ved sletning af booking:', error);
        alert('Der opstod en fejl ved sletning. Prøv venligst igen.');
      }
    }
  };

  const isSlotUnavailable = (day, timeSlot) => {
    const isWednesday = day.getDay() === 3; // 0 er søndag, 3 er onsdag
    return isWednesday && (timeSlot === "17-20" || timeSlot === "20-23");
  };

  const handleCellClick = (day, timeSlot, booking) => {
    const slotDate = new Date(day);
    slotDate.setHours(parseInt(timeSlot.split('-')[0]), 0, 0, 0);
    
    if (isSlotUnavailable(day, timeSlot)) {
      return; // Gør intet hvis slottet er utilgængeligt
    }
    
    if (booking) {
      if (booking.userId === currentUser.uid) {
        setEditingBooking(booking);
        setSelectedSlot(slotDate);
        setNumberOfStudents(booking.numberOfStudents);
        setVehicleType(booking.vehicleType);
      } else if (isAdmin) {
        setSelectedBooking(booking);
      }
    } else if (!isDateInPast(day)) {
      setSelectedSlot(slotDate);
      setEditingBooking(null);
      setNumberOfStudents(1);
      setVehicleType('bil');
    }
  };

  const getDaysArray = () => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(weekStart);
      day.setDate(day.getDate() + i);
      days.push(day);
    }
    return days;
  };

  const getBookingForSlot = (day, timeSlot) => {
    const [start] = timeSlot.split('-');
    const slotDate = new Date(day);
    slotDate.setHours(parseInt(start), 0, 0, 0);
    const booking = bookings.find(booking => {
      const bookingDate = booking.date;
      return bookingDate.getFullYear() === slotDate.getFullYear() &&
             bookingDate.getMonth() === slotDate.getMonth() &&
             bookingDate.getDate() === slotDate.getDate() &&
             bookingDate.getHours() === slotDate.getHours();
    });
    console.log('Booking found for', slotDate, ':', booking);
    return booking;
  };

  const handleDateChange = (date) => {
    const selectedWeekStart = getMonday(date);
    setSelectedDate(date);
    setWeekStart(selectedWeekStart);
  };

  return (
    <div className="min-h-screen bg-gray-50 text-gray-800">
      <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-extrabold mb-4 sm:mb-8 text-center tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black">
          Bestil bane
        </h2>
        <div className="bg-white rounded-lg shadow-lg p-2 sm:p-4 mb-4 sm:mb-8">
          <div className="flex flex-col sm:flex-row justify-between items-center mb-4 space-y-2 sm:space-y-0">
            <button 
              onClick={goToPreviousWeek} 
              className={`p-2 bg-black text-white rounded-lg transition duration-300 ${weekStart <= getCurrentWeekStart() ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-800'}`}
              disabled={weekStart <= getCurrentWeekStart()}
            >
              <FaChevronLeft />
            </button>
            <div className="flex items-center space-x-2">
              <span className="text-sm sm:text-base font-semibold">{`Uge ${weekStart.getWeek()} - ${weekStart.getFullYear()}`}</span>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                locale={da}
                dateFormat="dd/MM/yyyy"
                className="p-1 sm:p-2 border rounded-lg text-sm"
                customInput={<button className="p-2 bg-black text-white rounded-lg hover:bg-gray-800 transition duration-300"><FaCalendarAlt /></button>}
                minDate={getCurrentWeekStart()}
              />
            </div>
            <button onClick={goToNextWeek} className="p-2 bg-black text-white rounded-lg hover:bg-gray-800 transition duration-300">
              <FaChevronRight />
            </button>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full border-collapse text-xs sm:text-sm">
              <thead className="bg-gray-100">
                <tr>
                  <th className="border p-2 sm:p-3">Tid</th>
                  {getDaysArray().map((day, index) => (
                    <th key={index} className="border p-2 sm:p-3">
                      <span className="hidden sm:inline">{day.toLocaleDateString('da-DK', { weekday: 'short', month: 'short', day: 'numeric' })}</span>
                      <span className="sm:hidden">{day.toLocaleDateString('da-DK', { weekday: 'short', day: 'numeric' })}</span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {timeSlots.map((timeSlot, timeIndex) => (
                  <tr key={timeIndex}>
                    <td className="border p-2 sm:p-3 font-semibold h-12 sm:h-20">{timeSlot}</td>
                    {getDaysArray().map((day, dayIndex) => {
                      const booking = getBookingForSlot(day, timeSlot);
                      const isPast = isDateInPast(day);
                      const isOwnBooking = booking && booking.userId === currentUser.uid;
                      const isSelected = selectedSlot && 
                        selectedSlot.getDate() === day.getDate() && 
                        selectedSlot.getHours() === parseInt(timeSlot.split('-')[0]);
                      const isUnavailable = isSlotUnavailable(day, timeSlot);

                      return (
                        <td 
                          key={dayIndex} 
                          className={`border p-1 sm:p-3 transition duration-300 text-center h-12 sm:h-20 ${
                            isPast ? 'bg-gray-200 cursor-not-allowed' :
                            isUnavailable ? 'bg-gray-300 cursor-not-allowed' :
                            isSelected ? 'bg-blue-200 border-2 border-blue-500' :
                            booking ? (isOwnBooking ? 'bg-green-100 cursor-pointer' : 'bg-red-100') : 
                            'hover:bg-gray-100 cursor-pointer'
                          }`}
                          onClick={() => handleCellClick(day, timeSlot, booking)}
                        >
                          {isPast || isUnavailable ? (
                            <div className="flex items-center justify-center h-full">-</div>
                          ) : booking ? (
                            <div className="flex flex-col items-center justify-center h-full">
                              {(isOwnBooking || isAdmin) && (
                                <>
                                  <span className="text-xs sm:text-sm">
                                    {booking.numberOfStudents}
                                  </span>
                                  <span className="text-xs sm:text-sm mt-1 flex items-center">
                                    {booking.vehicleType === 'bil' ? (
                                      <FaCar className="sm:mr-1" />
                                    ) : (
                                      <FaMotorcycle className="sm:mr-1" />
                                    )}
                                    <span className="hidden sm:inline">
                                      {booking.vehicleType === 'bil' ? 'Bil' : 'MC'}
                                    </span>
                                  </span>
                                  {isAdmin && (
                                    <span className="text-xs mt-1 truncate max-w-full hidden sm:block">
                                      {booking.koereskole.length > 10 
                                        ? `${booking.koereskole.substring(0, 10)}...` 
                                        : booking.koereskole}
                                    </span>
                                  )}
                                </>
                              )}
                            </div>
                          ) : (
                            <div className="flex items-center justify-center h-full">&nbsp;</div>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {(selectedSlot || editingBooking) && (
          <div className="bg-white rounded-lg shadow-lg p-2 sm:p-4">
            <h3 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6">
              {editingBooking ? 'Rediger booking' : 'Ny booking'}
            </h3>
            <p className="mb-4 text-sm sm:text-base"><strong>Valgt dato og tid:</strong> {selectedSlot.toLocaleDateString('da-DK')} {`${selectedSlot.getHours().toString().padStart(2, '0')}00-${(selectedSlot.getHours() + 3).toString().padStart(2, '0')}00`}</p>
            <div className="mb-6">
              <label htmlFor="students" className="block text-sm font-medium text-gray-700 mb-2">
                Antal elever
              </label>
              <select
                id="students"
                value={numberOfStudents}
                onChange={(e) => setNumberOfStudents(parseInt(e.target.value))}
                className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value={1}>1 elev</option>
                <option value={2}>2 elever</option>
                <option value={3}>3 elever</option>
              </select>
            </div>
            <div className="mb-6">
              <label htmlFor="vehicleType" className="block text-sm font-medium text-gray-700 mb-2">
                Køretøjstype
              </label>
              <select
                id="vehicleType"
                value={vehicleType}
                onChange={(e) => setVehicleType(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="bil">Bil</option>
                <option value="motorcykel">Motorcykel</option>
              </select>
            </div>
            <div className="flex flex-col sm:flex-row justify-between space-y-2 sm:space-y-0 sm:space-x-2">
              <button
                onClick={handleBooking}
                className="w-full sm:w-auto bg-black text-white p-3 rounded-lg hover:bg-gray-800 transition duration-300 text-lg font-medium"
              >
                {editingBooking ? 'Opdater booking' : 'Bekræft booking'}
              </button>
              {editingBooking && (
                <button
                  onClick={() => handleDelete(editingBooking.id)}
                  className="w-full sm:w-auto bg-red-500 text-white p-3 rounded-lg hover:bg-red-600 transition duration-300 text-lg font-medium"
                >
                  Slet booking
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      {confirmDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-sm w-full">
            <h3 className="text-lg font-bold mb-4">Bekræft sletning</h3>
            <p className="mb-4">Er du sikker på, at du vil slette denne booking?</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setConfirmDelete(null)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-full hover:bg-gray-400 transition-colors duration-200"
              >
                Annuller
              </button>
              <button
                onClick={confirmDeleteBooking}
                className="px-4 py-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors duration-200"
              >
                Slet booking
              </button>
            </div>
          </div>
        </div>
      )}
      
      {selectedBooking && isAdmin && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-sm w-full">
            <h3 className="text-lg font-bold mb-4">Booking detaljer</h3>
            <div className="space-y-2">
              <p><FaCalendarAlt className="inline mr-2" /> {selectedBooking.date.toLocaleDateString('da-DK')} {selectedBooking.timeSlot}</p>
              <p><FaUser className="inline mr-2" /> {selectedBooking.numberOfStudents} elev(er)</p>
              <p>
                {selectedBooking.vehicleType === 'bil' ? (
                  <><FaCar className="inline mr-2" /> Bil</>
                ) : (
                  <><FaMotorcycle className="inline mr-2" /> Motorcykel</>
                )}
              </p>
              <p><FaSchool className="inline mr-2" /> {selectedBooking.koereskole}</p>
            </div>
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => setSelectedBooking(null)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-full hover:bg-gray-400 transition-colors duration-200"
              >
                Luk
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

Date.prototype.getWeek = function() {
  var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
  var dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  return Math.ceil((((d - yearStart) / 86400000) + 1)/7);
};

export default Kalender;