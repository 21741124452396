import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { FaBars, FaTimes } from 'react-icons/fa';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

function Navigation() {
  const { currentUser, logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (path) => {
    return location.pathname === path;
  };

  useEffect(() => {
    const checkApprovalStatus = async () => {
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setIsApproved(userSnap.data().isApproved);
        }
      }
    };

    checkApprovalStatus();
  }, [currentUser]);

  const handleNavigation = (path) => {
    setIsMenuOpen(false);
    navigate(path);
  };

  const handleLogout = () => {
    setIsMenuOpen(false);
    logout();
  };

  return (
    <nav className="bg-white border-b border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="text-2xl font-bold text-black">
              Gislinge Manøvrebane
            </Link>
          </div>
          <div className="hidden sm:flex sm:items-center sm:space-x-8">
            {currentUser && isApproved && (
              <>
                <Link 
                  to="/bestil-bane" 
                  className={`text-gray-600 hover:text-black transition-colors duration-300 ${
                    isActive('/bestil-bane') ? 'font-bold border-b-2 border-black' : ''
                  }`}
                >
                  Bestil bane
                </Link>
                <Link 
                  to="/bookinger" 
                  className={`text-gray-600 hover:text-black transition-colors duration-300 ${
                    isActive('/bookinger') ? 'font-bold border-b-2 border-black' : ''
                  }`}
                >
                  Mine Bookinger
                </Link>
              </>
            )}
            {currentUser && currentUser.role === 'admin' && (
              <Link 
                to="/admin" 
                className={`text-gray-600 hover:text-black transition-colors duration-300 ${
                  isActive('/admin') ? 'font-bold border-b-2 border-black' : ''
                }`}
              >
                Admin
              </Link>
            )}
            {currentUser && (
              <Link 
                to="/profil" 
                className={`text-gray-600 hover:text-black transition-colors duration-300 ${
                  isActive('/profil') ? 'font-bold border-b-2 border-black' : ''
                }`}
              >
                Profil
              </Link>
            )}
            {!currentUser ? (
              <>
                <Link 
                  to="/login" 
                  className={`text-gray-600 hover:text-black transition-colors duration-300 ${
                    isActive('/login') ? 'font-bold border-b-2 border-black' : ''
                  }`}
                >
                  Log ind
                </Link>
                <Link 
                  to="/tilmeld" 
                  className="px-4 py-2 rounded-md text-white bg-black hover:bg-gray-800 transition duration-300"
                >
                  Tilmeld
                </Link>
              </>
            ) : (
              <button onClick={logout} className="text-gray-600 hover:text-black transition-colors duration-300">
                Log ud
              </button>
            )}
          </div>
          <div className="sm:hidden flex items-center">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-600 hover:text-black focus:outline-none"
            >
              {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div className="sm:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <button 
              onClick={() => handleNavigation('/')}
              className={`block w-full text-left px-3 py-2 text-gray-600 hover:text-black transition-colors duration-300 ${
                isActive('/') ? 'font-bold bg-gray-100' : ''
              }`}
            >
              Forside
            </button>
            {currentUser && isApproved && (
              <>
                <button 
                  onClick={() => handleNavigation('/bestil-bane')}
                  className={`block w-full text-left px-3 py-2 text-gray-600 hover:text-black transition-colors duration-300 ${
                    isActive('/bestil-bane') ? 'font-bold bg-gray-100' : ''
                  }`}
                >
                  Bestil bane
                </button>
                <button 
                  onClick={() => handleNavigation('/bookinger')}
                  className={`block w-full text-left px-3 py-2 text-gray-600 hover:text-black transition-colors duration-300 ${
                    isActive('/bookinger') ? 'font-bold bg-gray-100' : ''
                  }`}
                >
                  Mine Bookinger
                </button>
              </>
            )}
            {currentUser && currentUser.role === 'admin' && (
              <button 
                onClick={() => handleNavigation('/admin')}
                className={`block w-full text-left px-3 py-2 text-gray-600 hover:text-black transition-colors duration-300 ${
                  isActive('/admin') ? 'font-bold bg-gray-100' : ''
                }`}
              >
                Admin
              </button>
            )}
            {currentUser && (
              <button 
                onClick={() => handleNavigation('/profil')}
                className={`block w-full text-left px-3 py-2 text-gray-600 hover:text-black transition-colors duration-300 ${
                  isActive('/profil') ? 'font-bold bg-gray-100' : ''
                }`}
              >
                Profil
              </button>
            )}
            {!currentUser ? (
              <>
                <button 
                  onClick={() => handleNavigation('/login')}
                  className={`block w-full text-left px-3 py-2 text-gray-600 hover:text-black transition-colors duration-300 ${
                    isActive('/login') ? 'font-bold bg-gray-100' : ''
                  }`}
                >
                  Log ind
                </button>
                <button 
                  onClick={() => handleNavigation('/tilmeld')}
                  className="block w-full text-left px-3 py-2 text-white bg-black hover:bg-gray-800 transition duration-300"
                >
                  Tilmeld
                </button>
              </>
            ) : (
              <button 
                onClick={handleLogout}
                className="block w-full text-left px-3 py-2 text-gray-600 hover:text-black transition-colors duration-300"
              >
                Log ud
              </button>
            )}
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navigation;
