import React, { useState } from 'react';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';

function Tilmeld() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [koereskole, setKoereskole] = useState('');
  const [fornavn, setFornavn] = useState('');
  const [efternavn, setEfternavn] = useState('');
  const [cvr, setCvr] = useState('');
  const [adresse, setAdresse] = useState('');
  const [by, setBy] = useState('');
  const [postnummer, setPostnummer] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length > 6) strength++;
    if (password.match(/[a-z]+/)) strength++;
    if (password.match(/[A-Z]+/)) strength++;
    if (password.match(/[0-9]+/)) strength++;
    if (password.match(/[$@#&!]+/)) strength++;
    return strength;
  };

  const passwordStrength = calculatePasswordStrength(password);

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Adgangskoderne matcher ikke');
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      await setDoc(doc(db, 'users', user.uid), {
        email,
        koereskole,
        fornavn,
        efternavn,
        cvr,
        adresse,
        by,
        postnummer,
        isApproved: false,
        role: 'user'
      });

      navigate('/'); // Redirect til forsiden efter succesfuld tilmelding
    } catch (error) {
      setError('Fejl ved tilmelding: ' + error.message);
      console.error('Fejl ved tilmelding:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Opret ny konto
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSignUp}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Adgangskode
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div className="mt-2 h-2 w-full bg-gray-200 rounded-full">
                <div
                  className={`h-full rounded-full ${
                    passwordStrength === 0 ? 'w-0' :
                    passwordStrength === 1 ? 'w-1/5 bg-red-500' :
                    passwordStrength === 2 ? 'w-2/5 bg-orange-500' :
                    passwordStrength === 3 ? 'w-3/5 bg-yellow-500' :
                    passwordStrength === 4 ? 'w-4/5 bg-lime-500' :
                    'w-full bg-green-500'
                  }`}
                ></div>
              </div>
            </div>

            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                Bekræft adgangskode
              </label>
              <div className="mt-1">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="koereskole" className="block text-sm font-medium text-gray-700">
                Køreskole
              </label>
              <div className="mt-1">
                <input
                  id="koereskole"
                  name="koereskole"
                  type="text"
                  required
                  value={koereskole}
                  onChange={(e) => setKoereskole(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="fornavn" className="block text-sm font-medium text-gray-700">
                Fornavn
              </label>
              <div className="mt-1">
                <input
                  id="fornavn"
                  name="fornavn"
                  type="text"
                  required
                  value={fornavn}
                  onChange={(e) => setFornavn(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="efternavn" className="block text-sm font-medium text-gray-700">
                Efternavn
              </label>
              <div className="mt-1">
                <input
                  id="efternavn"
                  name="efternavn"
                  type="text"
                  required
                  value={efternavn}
                  onChange={(e) => setEfternavn(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="cvr" className="block text-sm font-medium text-gray-700">
                CVR-nummer
              </label>
              <div className="mt-1">
                <input
                  id="cvr"
                  name="cvr"
                  type="text"
                  required
                  value={cvr}
                  onChange={(e) => setCvr(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="adresse" className="block text-sm font-medium text-gray-700">
                Adresse
              </label>
              <div className="mt-1">
                <input
                  id="adresse"
                  name="adresse"
                  type="text"
                  required
                  value={adresse}
                  onChange={(e) => setAdresse(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="by" className="block text-sm font-medium text-gray-700">
                By
              </label>
              <div className="mt-1">
                <input
                  id="by"
                  name="by"
                  type="text"
                  required
                  value={by}
                  onChange={(e) => setBy(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="postnummer" className="block text-sm font-medium text-gray-700">
                Postnummer
              </label>
              <div className="mt-1">
                <input
                  id="postnummer"
                  name="postnummer"
                  type="text"
                  required
                  value={postnummer}
                  onChange={(e) => setPostnummer(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            {error && (
              <div className="text-red-500 text-sm mt-2">
                {error}
              </div>
            )}

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Opret konto
              </button>
            </div>
          </form>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">
                  Har du allerede en konto?
                </span>
              </div>
            </div>

            <div className="mt-6">
              <Link
                to="/login"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-blue-600 bg-white hover:bg-gray-50"
              >
                Log ind her
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tilmeld;
