import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import './styles/custom.css';
import { AuthProvider } from './contexts/AuthContext';
import Navigation from './components/Navigation';
import Forside from './components/Forside';
import Bookinger from './components/Bookinger';
import Login from './components/Login';
import Tilmeld from './components/Tilmeld';
import Admin from './components/Admin';
import Kalender from './components/Kalender';
import PrivateRoute from './components/PrivateRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './components/Footer'; // Importér Footer-komponenten
import GlemtKode from './components/GlemtKode';
import OffentligeNyheder from './components/OffentligeNyheder';
import Profil from './components/Profil';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Navigation />
          <Routes>
            <Route path="/" element={<Forside />} />
            <Route path="/bestil-bane" element={
              <PrivateRoute requiredRole="approved">
                <Kalender />
              </PrivateRoute>
            } />
            <Route path="/login" element={<Login />} />
            <Route path="/tilmeld" element={<Tilmeld />} />
            <Route path="/admin" element={
              <PrivateRoute requiredRole="admin">
                <Admin />
              </PrivateRoute>
            } />
            <Route path="/bookinger" element={
              <PrivateRoute requiredRole="approved">
                <Bookinger />
              </PrivateRoute>
            } />
            <Route path="/glemt-kode" element={<GlemtKode />} />
            <Route path="/nyheder" element={<OffentligeNyheder />} />
            <Route path="/profil" element={
              <PrivateRoute>
                <Profil />
              </PrivateRoute>
            } />
          </Routes>
          <ToastContainer position="bottom-right" />
          <Footer /> {/* Tilføj Footer-komponenten her */}
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
