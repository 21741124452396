import React, { useState } from 'react';
import Bookinger from './Bookinger';
import Brugere from './Brugere';
import Økonomi from './Økonomi';
import Nyheder from './Nyheder';
import AdminBookings from './AdminBookings';
import Fakturaer from './Fakturaer';
import Mail from './Mail';
import { FaCalendarAlt, FaUsers, FaChartLine, FaNewspaper, FaFileInvoiceDollar, FaEnvelope } from 'react-icons/fa';

function Admin() {
  const [activeTab, setActiveTab] = useState('bookinger');

  const tabs = [
    { id: 'bookinger', label: 'Bookinger', icon: FaCalendarAlt },
    { id: 'brugere', label: 'Brugere', icon: FaUsers },
    { id: 'økonomi', label: 'Økonomi', icon: FaChartLine },
    { id: 'fakturaer', label: 'Fakturaer', icon: FaFileInvoiceDollar },
    { id: 'nyheder', label: 'Nyheder', icon: FaNewspaper },
    { id: 'mail', label: 'Mail', icon: FaEnvelope },
  ];

  return (
    <div className="min-h-screen bg-gray-50 text-gray-800">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl sm:text-4xl md:text-5xl font-extrabold mb-8 tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-gray-700 via-gray-900 to-black">
          Admin Dashboard
        </h1>
        
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="flex flex-wrap border-b">
            {tabs.map((tab) => (
              <button 
                key={tab.id}
                onClick={() => setActiveTab(tab.id)} 
                className={`flex-1 py-3 px-2 sm:px-4 text-center font-medium transition duration-300 ${
                  activeTab === tab.id 
                    ? 'bg-gray-100 text-black border-b-2 border-black' 
                    : 'text-gray-500 hover:bg-gray-50'
                }`}
              >
                <tab.icon className="inline-block mr-1 sm:mr-2 mb-1" />
                <span className="hidden sm:inline">{tab.label}</span>
              </button>
            ))}
          </div>

          <div className="p-4 sm:p-6">
            {activeTab === 'bookinger' && <AdminBookings />}
            {activeTab === 'brugere' && <Brugere />}
            {activeTab === 'økonomi' && <Økonomi />}
            {activeTab === 'fakturaer' && <Fakturaer />}
            {activeTab === 'nyheder' && <Nyheder />}
            {activeTab === 'mail' && <Mail />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
