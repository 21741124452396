import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, updateDoc, doc, getDoc, deleteDoc } from 'firebase/firestore';
import { FaDownload, FaCheck, FaTrash, FaSearch, FaSortAmountDown, FaSortAmountUp } from 'react-icons/fa';
import { jsPDF } from "jspdf";
import "jspdf-autotable";

function Fakturaer() {
  const [fakturaer, setFakturaer] = useState([]);
  const [filteredFakturaer, setFilteredFakturaer] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingFaktura, setEditingFaktura] = useState(null);
  const [editedBookings, setEditedBookings] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('descending');
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [statusFilter, setStatusFilter] = useState('alle');

  useEffect(() => {
    fetchFakturaer();
  }, []);

  useEffect(() => {
    filterAndSortFakturaer();
  }, [searchTerm, fakturaer, sortOrder, statusFilter]);

  const fetchFakturaer = async () => {
    try {
      setLoading(true);
      const fakturaerCollection = collection(db, 'invoices');
      const fakturaerSnapshot = await getDocs(fakturaerCollection);
      const fakturaerList = fakturaerSnapshot.docs.map(doc => {
        const fakturaData = doc.data();
        return {
          id: doc.id,
          ...fakturaData,
          date: fakturaData.date.toDate(),
          koereskole: fakturaData.koereskole // Vi antager nu, at dette er gemt direkte i fakturaen
        };
      });
      setFakturaer(fakturaerList);
      setLoading(false);
    } catch (error) {
      console.error('Fejl ved hentning af fakturaer:', error);
      setLoading(false);
    }
  };

  const filterAndSortFakturaer = () => {
    let filtered = fakturaer.filter(faktura => {
      const invoiceNumber = faktura.invoiceNumber?.toString().toLowerCase() || '';
      const koereskole = faktura.koereskole?.toString().toLowerCase() || '';
      const searchTermLower = searchTerm.toLowerCase();

      const matchesSearch = invoiceNumber.includes(searchTermLower) || koereskole.includes(searchTermLower);
      const matchesStatus = 
        statusFilter === 'alle' || 
        (statusFilter === 'betalt' && faktura.status === 'Betalt') ||
        (statusFilter === 'ubetalt' && faktura.status === 'Sendt');

      return matchesSearch && matchesStatus;
    });

    filtered.sort((a, b) => {
      return sortOrder === 'ascending' ? a.date - b.date : b.date - a.date;
    });

    setFilteredFakturaer(filtered);
  };

  const toggleSortOrder = () => {
    setSortOrder(prevOrder => prevOrder === 'ascending' ? 'descending' : 'ascending');
  };

  const handleDownload = async (fakturaId) => {
    try {
      const fakturaRef = doc(db, 'invoices', fakturaId);
      const fakturaSnap = await getDoc(fakturaRef);
      
      if (fakturaSnap.exists()) {
        const fakturaData = fakturaSnap.data();
        const userRef = doc(db, 'users', fakturaData.userId);
        const userSnap = await getDoc(userRef);
        const userData = userSnap.data();

        const pdfDoc = new jsPDF();
        
        // Tilføj overskrift
        pdfDoc.setFontSize(28);
        pdfDoc.setFont(undefined, 'bold');
        pdfDoc.text("FAKTURA", 20, 30);
        
        // Tilføj firmanavn med større skrift og fed
        pdfDoc.setFontSize(10);
        pdfDoc.setFont(undefined, 'bold');
        pdfDoc.text("GISLINGE MANØVREBANE ApS", 120, 45);

        // Resten af firmaoplysningerne
        pdfDoc.setFontSize(10);
        pdfDoc.setFont(undefined, 'normal');
        pdfDoc.text("Trønninge Nord 1,", 120, 52);
        pdfDoc.text("4532 Gislinge", 120, 57);
        pdfDoc.text("CVR-nr.: 35050957", 120, 62);
        pdfDoc.text("Tlf.: 40961230", 120, 67);
        pdfDoc.text("E-mail: mx@mail.dk", 120, 72);
        
        // Tilføj kundeoplysninger (i venstre side)
        pdfDoc.text(`${userData.koereskole}`, 20, 45);
        pdfDoc.text(`${userData.adresse || ''}`, 20, 50);
        pdfDoc.text(`${userData.postnummer || ''} ${userData.by || ''}`, 20, 55);
        pdfDoc.text(`CVR-nr. ${userData.cvr || ''}`, 20, 60);
        
        // Tilføj fakturadetaljer (nu i højre side)
        pdfDoc.text(`Fakturanr: ${fakturaData.invoiceNumber}`, 120, 80);
        pdfDoc.text(`Fakturadato: ${fakturaData.date.toDate().toLocaleDateString('da-DK')}`, 120, 85);
        
        // Tilføj en tynd linje
        pdfDoc.setLineWidth(0.1);
        pdfDoc.line(20, 95, 190, 95);
        
        // Opret tabel med bookinger
        const tableData = await Promise.all(fakturaData.bookings.map(async (booking) => {
          const bookingRef = doc(db, 'bookings', booking.id);
          const bookingSnap = await getDoc(bookingRef);
          const bookingData = bookingSnap.data();
          return [
            bookingData.date.toDate().toLocaleDateString('da-DK'),
            bookingData.timeSlot,
            bookingData.numberOfStudents,
            `${booking.price} DKK`
          ];
        }));

        pdfDoc.autoTable({
          head: [['Dato', 'Tidspunkt', 'Antal elever', 'Pris']],
          body: tableData,
          startY: 105,
          theme: 'plain',
          styles: { 
            fontSize: 9, 
            cellPadding: 5,
            lineWidth: 0
          },
          headStyles: { 
            fillColor: [255, 255, 255], 
            textColor: [0, 0, 0], 
            fontStyle: 'bold',
            lineWidth: 0
          },
          columnStyles: {
            0: { cellWidth: 'auto' },
            1: { cellWidth: 'auto' },
            2: { cellWidth: 'auto', halign: 'center' },
            3: { cellWidth: 'auto', halign: 'right' }
          },
          margin: { left: 20, right: 20 },
          tableWidth: 'auto'
        });

        // Beregn totaler
        const subtotal = fakturaData.amount / 1.25;
        const moms = fakturaData.amount - subtotal;

        // Tilføj totaler
        let yPos = pdfDoc.lastAutoTable.finalY + 15;
        pdfDoc.setFontSize(9);
        pdfDoc.text(`Momsfrit beløb: 0,00 - Momspligtigt beløb: ${subtotal.toFixed(2)}`, 20, yPos);
        pdfDoc.text(`Subtotal:`, 140, yPos);
        pdfDoc.text(`${subtotal.toFixed(2)}`, 190, yPos, { align: 'right' });
        pdfDoc.text(`25% moms:`, 140, yPos + 7);
        pdfDoc.text(`${moms.toFixed(2)}`, 190, yPos + 7, { align: 'right' });
        
        // Tilføj en tynd linje før total
        pdfDoc.setLineWidth(0.1);
        pdfDoc.line(140, yPos + 10, 190, yPos + 10);
        
        pdfDoc.setFontSize(11);
        pdfDoc.setFont(undefined, 'bold');
        pdfDoc.text(`Total DKK:`, 140, yPos + 18);
        pdfDoc.text(`${fakturaData.amount.toFixed(2)}`, 190, yPos + 18, { align: 'right' });

        // Juster y-positionen for betalingsinformation
        yPos += 30;

        // Tilføj betalingsbetingelser
        pdfDoc.setFontSize(9);
        pdfDoc.setFont(undefined, 'normal');
        pdfDoc.text("Betalingsbetingelser: Netto kontant", 20, yPos);

        // Beregn betalingsdato
        const calculatePaymentDate = () => {
          const today = new Date(fakturaData.date.toDate());
          let paymentDate = new Date(today.getFullYear(), today.getMonth() + 2, 1);
          return paymentDate.toLocaleDateString('da-DK', { day: 'numeric', month: 'long', year: 'numeric' });
        };

        const paymentDate = calculatePaymentDate();

        // Tilføj betalingsinformation
        yPos += 10;
        pdfDoc.text(`Beløbet bedes indbetalt senest ${paymentDate}`, 20, yPos);
        yPos += 10;
        pdfDoc.text("Betalingsmetoder:", 20, yPos);
        yPos += 5;
        pdfDoc.text("Bankoverførsel: Reg.nr: 2730. Kontonr.: 6277886985", 20, yPos);

        // Gem PDF'en
        pdfDoc.save(`faktura_${fakturaData.invoiceNumber}_${userData.koereskole}.pdf`);
      }
    } catch (error) {
      console.error('Fejl ved download af faktura:', error);
    }
  };

  const handleRegisterPayment = async (fakturaId) => {
    try {
      const fakturaRef = doc(db, 'invoices', fakturaId);
      await updateDoc(fakturaRef, { status: 'Betalt' });
      fetchFakturaer();
    } catch (error) {
      console.error('Fejl ved registrering af betaling:', error);
    }
  };

  const handleDelete = async (fakturaId) => {
    setConfirmDelete(fakturaId);
  };

  const confirmDeleteFaktura = async () => {
    if (!confirmDelete) return;

    try {
      const fakturaRef = doc(db, 'invoices', confirmDelete);
      const fakturaDoc = await getDoc(fakturaRef);
      const fakturaData = fakturaDoc.data();

      // Opdater alle bookings til ikke-faktureret
      for (const booking of fakturaData.bookings) {
        const bookingRef = doc(db, 'bookings', booking.id);
        await updateDoc(bookingRef, { invoiced: false });
      }

      // Slet fakturaen
      await deleteDoc(fakturaRef);

      setConfirmDelete(null);
      fetchFakturaer();
    } catch (error) {
      console.error('Fejl ved sletning af faktura:', error);
    }
  };

  // Opdater denne funktion til at vise det korrekte beløb uden at trække moms fra
  const formatAmount = (amount) => {
    return amount.toFixed(2);
  };

  if (loading) {
    return <div>Indlæser fakturaer...</div>;
  }

  return (
    <div className="fakturaer p-4 sm:p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl sm:text-4xl font-extrabold mb-6 sm:mb-8 text-center text-gray-800">Fakturaer</h2>
      
      <div className="max-w-7xl mx-auto mb-6">
        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
          <div className="relative flex-grow">
            <input
              type="text"
              placeholder="Søg efter fakturanr. eller modtager..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-3 pl-10 pr-4 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            className="p-3 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="alle">Alle fakturaer</option>
            <option value="betalt">Betalte fakturaer</option>
            <option value="ubetalt">Ubetalte fakturaer</option>
          </select>
          <button
            onClick={toggleSortOrder}
            className="p-3 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent flex items-center justify-center"
          >
            {sortOrder === 'ascending' ? <FaSortAmountUp /> : <FaSortAmountDown />}
            <span className="ml-2 hidden sm:inline">Sorter {sortOrder === 'ascending' ? 'ældste først' : 'nyeste først'}</span>
          </button>
        </div>
      </div>

      <div className="max-w-7xl mx-auto">
        <div className="hidden sm:block"> {/* Desktop version */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <table className="w-full">
              <thead className="bg-gray-800 text-white">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Fakturanr.</th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Status</th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Afsendelsesdato</th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Modtager</th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Beløb (inkl. moms)</th>
                  <th className="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider">Handlinger</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredFakturaer.map((faktura) => (
                  <tr key={faktura.id} className="hover:bg-gray-50 transition-colors duration-200">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{faktura.invoiceNumber}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{faktura.status}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{faktura.date.toLocaleDateString('da-DK')}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{faktura.koereskole}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatAmount(faktura.amount)} DKK
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        onClick={() => handleDownload(faktura.id)}
                        className="text-blue-600 hover:text-blue-900 mr-2 px-3 py-1 bg-blue-100 rounded-full"
                      >
                        <FaDownload className="inline mr-1" /> Download
                      </button>
                      {faktura.status !== 'Betalt' && (
                        <>
                          <button
                            onClick={() => handleRegisterPayment(faktura.id)}
                            className="text-green-600 hover:text-green-900 mr-2 px-3 py-1 bg-green-100 rounded-full"
                          >
                            <FaCheck className="inline mr-1" /> Marker betalt
                          </button>
                          <button
                            onClick={() => handleDelete(faktura.id)}
                            className="text-red-600 hover:text-red-900 px-3 py-1 bg-red-100 rounded-full"
                          >
                            <FaTrash className="inline mr-1" /> Slet
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="sm:hidden"> {/* Mobile version */}
          <div className="space-y-4">
            {filteredFakturaer.map((faktura) => (
              <div key={faktura.id} className="bg-white rounded-lg shadow-lg p-4">
                <div className="flex justify-between items-center mb-2">
                  <div className="flex items-center">
                    <span className="font-medium text-gray-900">{faktura.invoiceNumber}</span>
                  </div>
                  <span className="text-sm text-gray-500">{faktura.status}</span>
                </div>
                <div className="mb-2">
                  <span className="text-sm text-gray-600">Modtager: </span>
                  <span className="text-sm font-medium">{faktura.koereskole}</span>
                </div>
                <div className="mb-2">
                  <span className="text-sm text-gray-600">Dato: </span>
                  <span className="text-sm">{faktura.date.toLocaleDateString('da-DK')}</span>
                </div>
                <div className="mb-4">
                  <span className="text-sm text-gray-600">Beløb: </span>
                  <span className="text-sm font-medium">{formatAmount(faktura.amount)} DKK</span>
                </div>
                <div className="flex flex-wrap justify-center space-x-2 space-y-2">
                  <button
                    onClick={() => handleDownload(faktura.id)}
                    className="text-blue-600 hover:text-blue-900 px-3 py-1 bg-blue-100 rounded-full"
                  >
                    <FaDownload className="inline mr-1" /> Download
                  </button>
                  {faktura.status !== 'Betalt' && (
                    <>
                      <button
                        onClick={() => handleRegisterPayment(faktura.id)}
                        className="text-green-600 hover:text-green-900 px-3 py-1 bg-green-100 rounded-full"
                      >
                        <FaCheck className="inline mr-1" /> Marker betalt
                      </button>
                      <button
                        onClick={() => handleDelete(faktura.id)}
                        className="text-red-600 hover:text-red-900 px-3 py-1 bg-red-100 rounded-full"
                      >
                        <FaTrash className="inline mr-1" /> Slet
                      </button>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {confirmDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-sm w-full">
            <h3 className="text-lg font-bold mb-4">Bekræft sletning</h3>
            <p className="mb-4">Er du sikker på, at du vil slette denne faktura? Alle tilknyttede bookinger vil blive markeret som ikke-faktureret.</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setConfirmDelete(null)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-full hover:bg-gray-400 transition-colors duration-200"
              >
                Annuller
              </button>
              <button
                onClick={confirmDeleteFaktura}
                className="px-4 py-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors duration-200"
              >
                Slet faktura
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Fakturaer;
